import React from "react";
import Layout from "@components/Layout";
import {
  BlogWrapper,
  BlogPreviewContainer,
  BlogItemPreview,
  BlogItemTitle,
  BlogItemDate,
} from "@styles/blog";
import { graphql } from "gatsby";
import { SectionHeader, SectionSubHeader } from "../styles/general";
import { Helmet } from "react-helmet";

export default ({ location, data }) => {
  const posts = data.allContentfulBlogPost.nodes;

  return (
    <Layout location={location}>
      <Helmet>
        <title>Blog</title>
      </Helmet>
      <BlogWrapper>
        <SectionHeader>My Thoughts</SectionHeader>
        <SectionSubHeader>
          which do not reflect those of my employer(s)
        </SectionSubHeader>
        <BlogPreviewContainer>
          {!posts && "No entries... yet?"}
          {posts
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(node => {
              const dateRepresentation = new Date(node.date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              console.log("Rendering " + node.date + " " + dateRepresentation);
              return (
                <BlogItemPreview key={node.slug} to={node.slug}>
                  <BlogItemTitle>{node.title}</BlogItemTitle>
                  <br></br>
                  <BlogItemDate>{dateRepresentation}</BlogItemDate>
                </BlogItemPreview>
              );
            })}
        </BlogPreviewContainer>
      </BlogWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulBlogPost {
      nodes {
        id
        date
        title
        slug
      }
    }
  }
`;
