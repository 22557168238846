import styled from "styled-components";
import mixins from "./mixins";
import { Link } from "gatsby";

export const BlogWrapper = styled.div`
  ${mixins.flexColCenter}
  font-size: 1.5em;
  /* height: calc(100% - 50px); */
  padding-bottom: 50px;
`;

export const BlogPreviewContainer = styled.div`
  margin-top: 25px;
  height: 200px;
  text-align: center;
`;

export const BlogItemPreview = styled(Link)`
  text-decoration: none;
  display: block;
  margin: 20px;
  border-radius: 5px;
  background: #0c2a3f;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  color: inherit;
  cursor: pointer;
  > * {
    cursor: pointer;
  }
  span {
    margin-right: 5px;
  }
  hr {
    display: inline-block;
    height: 1px;
    width: 30px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 7px 0.5em;
    padding: 0;
  }
`;
export const BlogItemDate = styled.span`
  font-size: 14px;
`;
export const BlogItemTitle = styled.span`
  color: rgb(100, 255, 218);
`;
export const BlogItemCategory = styled.span``;
